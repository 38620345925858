html, body {
    height: 100%;
}

.wrapper {
    min-height: 100vh;
}

:root {
    --darken-gradient-10: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    --darken-gradient-20: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    --darken-gradient-25: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
    --darken-gradient-30: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    --minty: #78C2AD;
}

.error-404-heading {
    font-size: 7rem;
    font-weight: bold;
    color: #F04124;
}

.minty-bg {
    background-color: var(--minty);
}

.minty-color {
    color: var(--minty);
}

.vertical-center {
    min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
    min-height: 40vh; /* These two lines are counted as one :-)       */
  
    display: flex;
    align-items: center;
  }

.error-404-container {
    background-color: #fff;
}

.base-hero {
    color: white;
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("Assets/charts2_header.jpg"); */
    /* background-image:  var(--darken-gradient-30), url("Assets/charts_header.jpg"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;
}

.forecast-hero {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("Assets/charts2_header.jpg"); */
    background-image:  var(--darken-gradient-30), url("Assets/charts_header.jpg");
}


 
